<template>
  <ApplicantDashboardLayout>
    <template #header>
      <Header>
        <template #route-back-component>
          <div class="tw-flex tw-items-center">
            <vue-feather class="tw-flex tw-mr-3" style="width: 24px; height: 24px;" type="arrow-left"
                         @click="$router.back()"></vue-feather>
            <label class="title">{{ $route.params.job_name }}</label>
          </div>
        </template>
        <template #right-header-component>
          <ProfileDropDown/>
        </template>
      </Header>
    </template>

    <template #dashboard-sideNav>
      <!--  Back button  -->
      <div class="tw-m-auto pt-5 lg:tw-w-10/12">
        <button class="tw-flex" @click="$router.back()">
          <ArrowLeftIcon style="color:#9CABB5"/>
          <p class="tw-font-bold tw-ml-2 tw-text-base">Back</p>
        </button>
      </div>
    </template>
    <template  #dashboard-content>
      <div v-if="loading">
        <Loader :loading="loading"></Loader>

      </div>
      <div v-else class="tw-p-4">
        <!--  Body  -->
        <div class="lg:tw-mt-12 tw-w-full lg:tw-w-3/5">
          <div class="tw-text-center lg:tw-text-left"><h1 class="heading">Offer Details</h1></div>

          <p class="sub-heading tw-mt-4 lg:tw-w-4/5">You have been offered a <strong> {{ candidate_getOffer.job_type }} </strong> role at <strong>
            {{ candidate_getOffer.company_name }} </strong>. Accepting means you are satisfied with the job summary.</p>
          <div class="frame px-3 d-flex tw-mt-36 tw-justify-between" @click="downloadFile">
            <div class="d-flex">
              <v-icon> mdi-file-outline</v-icon>
              <div>
                <span class="file-name pl-2 mt-3">{{ shortenedTitle(candidate_getOffer.file_name) }}</span>
                <p class="bytes pl-2">{{ getFileSize(candidate_getOffer.file_size) }}</p>
              </div>
            </div>
            <ArrowDownIcon style="color: #475661"/>
          </div>
          <div class="tw-pt-10">
            <p>Warm Regards,</p>
            <span>{{ candidate_getOffer.company_name }} Hiring Team</span>
          </div>
          <div v-if="!candidate_getOffer.acceptance_status && !candidate_getOffer.decline_status" class="tw-flex tw-justify-center tw-mb-10 tw-pt-24 lg:tw-justify-start">
            <CandidateRejectModal />
            <BaseButton button-text="Accept" class="tw-ml-3" @click="acceptOffer"/>
          </div>
        </div>
      </div>
    </template>
  </ApplicantDashboardLayout>
</template>

<script>

import {ArrowDownIcon, ArrowLeftIcon} from "vue-feather-icons"
import CandidateRejectModal from "@/components/dashboard/jobs/candidateOfferState/CandidateRejectModal";
import BaseButton from "@/components/reusables/BaseButton";
import ApplicantDashboardLayout from "@/components/layout/ApplicantDashboardLayout";
import ProfileDropDown from "@/components/reusables/ProfileDropDown";
import Header from "@/components/reusables/Header";
import {mapGetters} from "vuex";
import Loader from "@/Loader";
import {viewLesson} from "@/services/utils/aws";

export default {
  name: "OfferDetailsState",
  components: {
    Loader,
    Header,
    ProfileDropDown, ApplicantDashboardLayout, BaseButton, CandidateRejectModal, ArrowLeftIcon, ArrowDownIcon
  },
  data(){
    return{
      loading:false,
      offer_letter:''
    }
  },
  computed: {
    ...mapGetters(['candidate_getOffer', 'candidate_acceptOffer'])
  },
  created() {
    this.loading = true
    let userData = JSON.parse(sessionStorage.getItem('userData'))
    let data = {}
    data.job_id = this.$route.params.job_id
    data.email = userData.email
    this.$store.dispatch('getCandidateOffer', data).finally(()=>this.loading=false)
  },
  watch:{
    candidate_getOffer: {
      handler(val) {
        const offer = JSON.parse(JSON.stringify(val))
            viewLesson({url: offer.offer_letter }).then(result => {
              console.log({result})
              this.offer_letter = result
            })
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    acceptOffer() {
      let userData = JSON.parse(sessionStorage.getItem('userData'))
      let data = {}
      data.job_id = this.$route.params.job_id
      data.email = userData.email
      this.$store.dispatch('candidateAcceptOffer', data).then(res => {
        console.log(res)
        this.$router.push({name: 'AppliedJobDetails'})
      })
    },
    shortenedTitle(file_name) {
      const shortenText = (text, length) => {
        if (text.length <= length) return text
        return text.substring(0, length) + '...'
      }
      return shortenText(file_name, 25)
    },
    getFileSize(size) {
      if (!size) return "";
      const i = Math.floor(Math.log(size) / Math.log(1024));
      return (
          (size / Math.pow(1024, i)).toFixed(2) * 1 +
          " " +
          ["B", "kB", "MB", "GB", "TB"][i]
      );
    },

    downloadFile() {
      let link = document.createElement("a");
      link.target = "_blank"
      link.download = this.offer_letter;
      link.href = this.offer_letter;
      link.click();
    }
  }
}
</script>

<style scoped lang="scss">
.frame {
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  box-sizing: border-box;
  border-radius: 5px;
  height: 65px;
  cursor: pointer;
  align-items: center;
  width: 600px;
  @media (max-width: 500px ) {
    width: 317px
  }
}

.heading {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  align-items: center;
  letter-spacing: -0.02em;
  color: #2B1C1C;
}

.sub-heading {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}

.file-name {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #475661;
}

.bytes {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #9CABB5;
}

.v-application p {
  margin-bottom: 2px;
}
</style>
