<template>
  <div>
    <BaseButton button-text="Decline" outlined @click="dialog = true"/>
    <v-dialog
        v-model="dialog"
        max-width="500px">
      <template v-slot:default="dialog">
        <v-card class="tw-p-6" style="border-radius: 16px">
          <div class="tw-flex tw-justify-between">
            <p class="reject-candidate">Decline Job Offer</p>
            <v-card-actions class="justify-end">
              <XIcon class="tw-mb-4" @click="dialog.value = false"/>
            </v-card-actions>
          </div>
          <v-combobox id="combo" v-model="reason" :items="reasons" hide-details label="Select a Reason"
                      placeholder="Select a reason"
                      solo>
          </v-combobox>
          <div class="tw-mt-5">
            <small>Specify Reason</small>
            <v-textarea v-model="message" hide-details outlined></v-textarea>
          </div>
          <div>
            <v-card-actions class="justify-end tw-mt-5">
              <BaseButton button-text="Back" outlined @click="dialog.value = false"/>
              <BaseButton :disabled="!reason" button-text="Decline" class="tw-ml-3" @click="rejectCandidate"/>
            </v-card-actions>
          </div>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>

import {XIcon} from "vue-feather-icons"
import BaseButton from "@/components/reusables/BaseButton";
import {mapGetters} from "vuex";


export default {
  name: "CandidateRejectModal",
  components: {XIcon, BaseButton},
  data() {
    return {
      dialog: false,
      message: '',
      reason: '',
      reasons: ['I lost interest',]
    }
  },
  computed: {
    ...mapGetters(['candidate_rejectOffer', 'candidate_getOffer'])
  },

  methods: {
    rejectCandidate() {
      let data = {
        offer: this.candidate_getOffer.id,
        reason: this.reason,
        more_reason: this.message
      }
      this.$store.dispatch('candidateDeclineOffer', data).then(res => {
        console.log(res)
        this.reason = ''
        this.message = ''
        this.dialog = false
        this.$router.push({name: 'AppliedJobDetails'})
      })
    }
  }
}
</script>

<style scoped>
.reject-candidate {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  align-items: center;
  letter-spacing: -0.02em;
  color: #2B1C1C;
}
</style>